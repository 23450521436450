import {useMemo, useState} from 'react';

import {Card, CardContent, Grid, IconButton, Menu} from '@mui/material';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Calendar, DotsThreeOutlineVertical} from '@phosphor-icons/react';
import {format as formatDate, parseISO} from 'date-fns';

import Tag from '@src/components/core/display/Tag';
import Link from '@src/components/core/navigation/Link';
import {formatTimeZone} from '@src/library/dates';

interface MeetingCardProps {
  asset: any;
  link: string;
  actions?: JSX.Element[];
}

const MeetingCard = (props: MeetingCardProps) => {
  const {actions, asset, link} = props;

  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorElement(undefined);
  };

  const meeting = asset.attributes.meeting.data;
  const title =
    asset.attributes.title === '' ? meeting.attributes.name : asset.attributes.title;
  const startTime = meeting.attributes.scheduledAt;

  const meetingTime = useMemo(() => {
    return `${formatDate(parseISO(startTime), 'do MMM')} · ${formatDate(
      parseISO(startTime),
      'h:mm a'
    )} ${formatTimeZone(parseISO(startTime))}`;
  }, [startTime]);

  const isOngoing = parseISO(startTime).getTime() <= Date.now();

  return (
    <>
      <Card sx={{overflow: 'unset'}} variant="outlined">
        <CardContent sx={{padding: '8px !important'}}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack direction="row" spacing={1}>
              <Stack spacing={0.5}>
                <Stack direction="row" spacing={2}>
                  <Link color="unset" href={link} underline="hover">
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      sx={{
                        maxWidth: '340px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                      tabIndex={0}
                    >
                      {title}
                    </Typography>
                  </Link>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tag
                      color={isOngoing ? 'red' : 'yellow'}
                      sx={{cursor: 'default !important'}}
                    >
                      {isOngoing ? 'In Progress' : 'Upcoming'}
                    </Tag>
                  </Box>
                </Stack>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Calendar />
                  <Typography color="secondary" fontSize="14px">
                    {meetingTime}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            {actions?.length > 0 && (
              <Grid item>
                <IconButton onClick={handleClick}>
                  <DotsThreeOutlineVertical weight="fill" />
                </IconButton>
              </Grid>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Menu
        PaperProps={{
          sx: {
            minWidth: '150px',
            boxShadow:
              '0 16px 32px 0 #00000019, 0 1px 4px 0 #00000026, 0 0 1px 0 #00000026',
          },
        }}
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        keepMounted
        open={Boolean(anchorElement)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={handleClose}
      >
        {actions.map((action, index) => {
          return (
            <Box key={index} onClick={handleClose}>
              {action}
            </Box>
          );
        })}
      </Menu>
    </>
  );
};

export default MeetingCard;
