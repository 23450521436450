import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface CircularProps extends CircularProgressProps {
  label?: string;
}

const Circular = (props: CircularProps) => {
  const {color, label = '', value} = props;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
      }}
    >
      <CircularProgress
        color={color}
        sx={{
          color: color === 'primary' ? '#3E5FE4' : '#5d6b8e',
        }}
        {...props}
      />
      {label && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '.6875rem',
              fontWeight: 500,
              color: '#5d6b8e',
            }}
          >
            {`${Math.round(value ?? 0)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Circular;
