import {useEffect, useState} from 'react';

import type {LiveblocksYjsProvider} from '@liveblocks/yjs';

export default function useLiveblocksProviderLoadingState(
  provider: LiveblocksYjsProvider<any, any, any, any> | undefined
) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!provider || isLoaded) {
      return;
    }

    if (provider.synced) {
      setIsLoaded(true);
      return;
    }

    const synced = (isSynced: boolean) => {
      if (isSynced) {
        setIsLoaded(true);
      }
    };

    provider.on('sync', synced);

    return () => {
      provider.off('sync', synced);
    };
  }, [provider, isLoaded]);

  return isLoaded;
}
