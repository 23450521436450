import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Active,
  CollisionDetection,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {createPortal} from 'react-dom';
import {Panel, PanelGroup} from 'react-resizable-panels';

import {DashboardCardId} from './cards/DashboardCardId';
import DashboardCard from './DashboardCard';
import DashboardColumn, {DashboardColumnResizer} from './DashboardColumn';
import NewCardButton from './NewCardButton';

interface Props {
  items: Record<UniqueIdentifier, UniqueIdentifier[]>;
  setItems: Dispatch<SetStateAction<Record<UniqueIdentifier, UniqueIdentifier[]>>>;
  columns: UniqueIdentifier[];
  setColumns: Dispatch<SetStateAction<UniqueIdentifier[]>>;
  panels: string;
  setPanels: Dispatch<SetStateAction<string>>;
  layoutMode: boolean;
}

export function Dashboard({
  columns,
  items,
  layoutMode,
  panels,
  setColumns,
  setItems,
  setPanels,
}: Props) {
  const [active, setActive] = useState<Active>(null);

  const lastOverId = useRef<UniqueIdentifier>(null);
  const recentlyMovedToNewContainer = useRef(false);
  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (active && active.id in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => {
            return container.id in items;
          }),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter((container) => {
                return (
                  container.id !== overId && containerItems.includes(container.id)
                );
              }),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{id: overId}];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = active.id;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{id: lastOverId.current}] : [];
    },
    [active, items]
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => {
      return items[key].includes(id);
    });
  };

  const getNextContainerId = () => {
    const containerCodePoints = Object.keys(items).map((item) => {
      return item.codePointAt(0);
    });
    const max = Math.max(...containerCodePoints);
    return String.fromCodePoint(max + 1);
  };

  const removeItem = (id: UniqueIdentifier) => {
    setItems((items) => {
      const containerId = findContainer(id);
      return {
        ...items,
        [containerId]: items[containerId].filter((item) => {
          return item != id;
        }),
      };
    });
  };

  const addItemToColumn = (item: UniqueIdentifier, columnId: UniqueIdentifier) => {
    setItems((items) => {
      return {
        ...items,
        [columnId]: [...items[columnId], item],
      };
    });
  };

  const removeColumn = (columnId: UniqueIdentifier) => {
    setColumns((columns) => {
      return columns.filter((column) => {
        return column !== columnId;
      });
    });
    setItems((items) => {
      const newItems = {...items};
      delete newItems[columnId];
      return newItems;
    });
  };

  const addColumn = (columnId: UniqueIdentifier, direction: 'left' | 'right') => {
    const nextColumnId = getNextContainerId();
    setColumns((columns) => {
      const index = columns.findIndex((column) => {
        return column == columnId;
      });
      const newColumns = [...columns];
      newColumns.splice(direction == 'left' ? index : index + 1, 0, nextColumnId);
      return newColumns;
    });
    setItems((items) => {
      return {
        ...items,
        [nextColumnId]: [],
      };
    });
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      sensors={sensors}
      onDragEnd={({active, over}) => {
        if (active.id in items && over?.id) {
          setColumns((columns) => {
            const activeIndex = columns.indexOf(active.id);
            const overIndex = columns.indexOf(over.id);

            return arrayMove(columns, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActive(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActive(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {
            setItems((items) => {
              const newItems = {...items};
              newItems[overContainer] = arrayMove(
                items[overContainer],
                activeIndex,
                overIndex
              );
              return newItems;
            });
          }
        }

        setActive(null);
      }}
      onDragOver={({active, over}) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            const newItems = {...items};
            newItems[activeContainer] = items[activeContainer].filter((item) => {
              return item !== active.id;
            });
            newItems[overContainer] = [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(newIndex, items[overContainer].length),
            ];

            return newItems;
          });
        }
      }}
      onDragStart={({active}) => {
        setActive(active);
      }}
    >
      <PanelGroup
        autoSaveId="home-dashboard"
        direction="horizontal"
        storage={{
          getItem(_name) {
            return panels;
          },
          setItem(_name, value) {
            setPanels(value);
          },
        }}
      >
        <SortableContext items={columns} strategy={horizontalListSortingStrategy}>
          {columns.map((columnId, index) => {
            return (
              <Fragment key={columnId}>
                <Panel
                  id={columnId as string}
                  order={index}
                  style={{
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'unset',
                  }}
                >
                  <DashboardColumn
                    active={active}
                    addCard={
                      <NewCardButton
                        items={items as Record<UniqueIdentifier, DashboardCardId[]>}
                        onSelect={(item) => {
                          return addItemToColumn(item, columnId);
                        }}
                      />
                    }
                    addColumn={addColumn}
                    columnId={columnId}
                    items={items[columnId]}
                    layoutMode={layoutMode}
                    removeColumn={removeColumn}
                    removeItem={removeItem}
                  />
                </Panel>
                {index !== columns.length - 1 && (
                  <DashboardColumnResizer disabled={!layoutMode || !!active} />
                )}
              </Fragment>
            );
          })}
        </SortableContext>
      </PanelGroup>
      {createPortal(
        active && (
          <DragOverlay>
            {active.id in items ? (
              <DashboardColumn
                active={active}
                columnId={active.id}
                items={items[active.id]}
                layoutMode={layoutMode}
              />
            ) : (
              <DashboardCard
                cardId={active.id as DashboardCardId}
                sx={{zIndex: 1, opacity: 0.5}}
              />
            )}
          </DragOverlay>
        ),
        document.body
      )}
    </DndContext>
  );
}
