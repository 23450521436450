import {useInboxNotifications} from '@liveblocks/react';
import {InboxNotification, InboxNotificationList} from '@liveblocks/react-ui';

import Box from '@mui/material/Box';
import {At} from '@phosphor-icons/react';

import LoadingView from '../layouts/core/app/LoadingView';
import DataGridZeroState from '../legacy/Navigator/DataGridZeroState';
import ToastAlert from '../alerts/ToastAlert';

interface Props {
  mentionsOnly?: boolean;
}

const NotificationList = ({mentionsOnly = true}: Props) => {
  const {inboxNotifications, isLoading} = useInboxNotifications();

  if (isLoading) {
    return <LoadingView />;
  }

  if (!inboxNotifications || inboxNotifications.length === 0) {
    return (
      <DataGridZeroState
        icon={<At />}
        message="Mentions from your colleagues will show up here"
        title="No Recent Mentions"
      />
    );
  }

  return (
    <Box>
      <InboxNotificationList
        style={{display: 'flex', flexDirection: 'column', gap: 8}}
      >
        {inboxNotifications.map((inboxNotification) => {
          return (
            <InboxNotification
              key={inboxNotification.id}
              inboxNotification={inboxNotification}
              kinds={{
                thread: ({inboxNotification}) => {
                  return (
                    <InboxNotification.Thread
                      href={`/api/liveblocks/thread-redirect?roomId=${inboxNotification.roomId}&threadId=${inboxNotification.threadId}`}
                      inboxNotification={inboxNotification}
                      showActions={false}
                      showRoomName={false}
                    />
                  );
                },
                $alert: mentionsOnly
                  ? () => {
                      return <></>;
                    }
                  : ({inboxNotification}) => {
                      const data = inboxNotification.activities[0].data;
                      return (
                        <ToastAlert
                          message={data.message}
                          severity={data.severity}
                          sx={{
                            maxWidth: 'unset !important',
                          }}
                          title={data.title}
                        />
                      );
                    },
              }}
            />
          );
        })}
      </InboxNotificationList>
    </Box>
  );
};

export default NotificationList;
