import {ReactNode} from 'react';

import {Box} from '@mui/material';
import clsx from 'clsx';

import Typography from '../Typography';

import css from './DataGridZeroState.module.css';

export type DataGridZeroStateProps = {
  classnames?: Array<string> | string;
  title?: string;
  message?: string;
  icon?: ReactNode;
  action?: ReactNode;
};

export default function DataGridZeroState({
  action,
  classnames,
  icon,
  message,
  title,
}: DataGridZeroStateProps) {
  return (
    <Box className={clsx([css['datagridzerostate'], classnames])}>
      <Box className={css['icon-header']} display="flex" justifyContent="center">
        {icon}
      </Box>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2">{message}</Typography>
      {action}
    </Box>
  );
}
