'use client';

import {ReactNode} from 'react';

import {Box, Stack} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Circular from '@components/core/display/progress/CircularProgress';

export interface Props {
  loadingText?: string;
  spinner?: ReactNode;
}

const LoadingView = (props: Props) => {
  const {loadingText, spinner} = props;

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Stack alignItems="center" direction="column" spacing={2}>
          {loadingText && <Typography component="h6">{loadingText}</Typography>}
          {spinner || (
            <Circular aria-label="loading" color="primary" variant="indeterminate" />
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default LoadingView;
