import {ReactNode} from 'react';

import {IconButton, SxProps, Theme} from '@mui/material';

const styles: SxProps<Theme> = {
  '&[data-active=true]': {
    backgroundColor: (theme) => {
      return theme.colors.gray['200'];
    },
  },
};

type Props = {
  isActive: boolean;
  isDisabled: boolean;
  children: ReactNode;
  label: string;
  onClick: () => void;
};

export default function ToolbarButton({
  children,
  isActive,
  isDisabled,
  label,
  onClick,
  ...props
}: Props) {
  return (
    <IconButton
      aria-label={label}
      data-active={isActive}
      disabled={isDisabled}
      sx={styles}
      onClick={onClick}
      {...props}
    >
      {children}
    </IconButton>
  );
}
