import {forwardRef} from 'react';

import {Box} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import {X} from '@phosphor-icons/react';

import {TagProps} from './Tag.types';

const getFontSize = (type, size) => {
  let fontSize: number;

  if (type === 'status') {
    fontSize = size === 'small' ? 11 : 12;
  } else if (size === 'small') {
    fontSize = 14;
  } else {
    fontSize = 16;
  }

  return fontSize;
};

const Tag = forwardRef<HTMLSpanElement, TagProps>((props: TagProps, ref) => {
  const {
    children,
    color,
    disableShadow,
    handleRemove,
    number,
    removable,
    size = 'small',
    sx,
    type,
    ...others
  } = props;

  const theme = useTheme();

  return (
    <Typography
      ref={ref}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        padding: '2px 8px',
        fontSize: `${getFontSize(type, size)}px`,
        fontWeight: 500,
        letterSpacing: '.5px',
        textTransform: type === 'status' ? 'uppercase' : 'inherit',
        overflow: 'hidden',
        color: (theme) => {
          return theme.colors[color]['600'];
        },
        backgroundColor: (theme) => {
          return theme.colors[color]['100'];
        },
        borderRadius: '4px',
        boxShadow: (theme) => {
          const border = `0 0 0 1px ${theme.colors[color]['300']}`;
          const shadow = `0 1px 0 1px ${theme.colors[color]['600']}50`;

          return [border, disableShadow ? undefined : shadow]
            .filter(Boolean)
            .join(', ');
        },
        transition: `background 200ms,
          border-color 200ms,
          box-shadow 200ms,
          color 200ms`,

        '& .MuiTypography-root': {
          paddingLeft: theme.spacing(1),
          fontSize: size === 'small' ? '14px' : '16px',
          fontWeight: 500,
        },

        '& svg': {
          marginLeft: theme.spacing(0.5),
          fontSize: size === 'small' ? '16px' : '20px',
          cursor: 'pointer',
        },

        ...sx,
      }}
      {...others}
    >
      {children}
      {number && <Typography>{number}</Typography>}
      {removable && <Box component={X} onClick={handleRemove} />}
    </Typography>
  );
});

Tag.displayName = 'Tag';

export default Tag;
