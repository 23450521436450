import {useEffect, useState} from 'react';

import {RoomProvider, useRoom} from '@liveblocks/react';
import Box from '@mui/material/Box';
import Collaboration from '@tiptap/extension-collaboration';
import Placeholder from '@tiptap/extension-placeholder';
import {EditorContent, Editor as TipTapEditor} from '@tiptap/react';
import {useUpdate} from 'react-use';

import EditorToolbar from '@src/components/editor/EditorToolbar';
import LoadingPlaceholder from '@src/components/editor/LoadingPlaceholder';
import useLiveblocksProviderLoadingState from '@src/hooks/useLiveblocksProviderLoadingState';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';
import useYjsRoom from '@src/hooks/useYjsRoom';
import Base from '@src/library/editor-extensions/base';

import {generateEntityRoomId} from '@tetra-next/liveblocks-rooms';

export default function PersonalNotes() {
  const {user} = useUser();
  const organizationId = user.org_id;
  const subdomain = useSubdomain();

  const roomId = generateEntityRoomId({
    subdomain,
    organizationId,
    entityType: 'personalNotes',
    entityUid: user.sub,
  });

  return (
    <RoomProvider id={roomId} initialPresence={{}}>
      <Editor />
    </RoomProvider>
  );
}

function Editor() {
  const room = useRoom();
  const {doc, provider} = useYjsRoom(room);
  const isLoaded = useLiveblocksProviderLoadingState(provider);
  const rerender = useUpdate();
  const [editor, setEditor] = useState<TipTapEditor>();

  useEffect(() => {
    if (!doc || !provider) {
      return;
    }

    let isMounted = true;

    const editor = new TipTapEditor({
      extensions: [
        ...Base,
        Placeholder.configure({
          placeholder: 'Make your personal notes and checklist here…',
        }),
        Collaboration.configure({
          document: doc,
        }),
      ],
      onTransaction() {
        requestAnimationFrame(() => {
          if (isMounted) {
            rerender();
          }
        });
      },
    });

    setEditor(editor);

    return () => {
      isMounted = false;
      editor.destroy();
    };
  }, [doc, provider]);

  if (!editor) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
        '.ProseMirror': {
          flex: 1,
          minHeight: 0,
          padding: '10px',
          fontSize: '16px',
          color: '#2d364e',
          '> * + *': {
            marginTop: '0.75em',
          },
          '> :first-of-type': {
            marginTop: 0,
          },
          a: {
            textDecoration: 'underline',
            color: '#3e5fe4',
          },
          blockquote: {
            marginRight: 0,
            marginLeft: '1rem',
            paddingLeft: '1rem',
            borderLeft: '2px solid #d6deea',
          },
          'ul, ol': {
            padding: '0 1rem',
          },
          'ul[data-type="taskList"]': {
            padding: 0,
            listStyle: 'none',
            '& li': {
              display: 'flex',
              '& > div': {
                flex: '1 1 auto',
              },
              '& > label': {
                flex: '0 0 auto',
                marginRight: '0.5rem',
                userSelect: 'none',
              },
              '& ul li, & ol li': {
                display: 'list-item',
              },
              '& ul[data-type="taskList"] > li': {
                display: 'flex',
              },
            },
            '& p': {
              margin: 0,
            },
          },
        },
        '.ProseMirror p.is-editor-empty:first-of-type::before': {
          content: 'attr(data-placeholder)',
          float: 'left',
          height: 0,
          color: '#aaa',
          pointerEvents: 'none',
        },
        '.ProseMirror-focused': {
          outline: 'none',
        },
        '.collaboration-cursor__caret': {
          position: 'relative',
          zIndex: 10,
          marginRight: '-1px',
          marginLeft: '-1px',
          borderRight: '1px solid',
          borderLeft: '1px solid',
          pointerEvents: 'none',
          userSelect: 'none',
        },
        '.collaboration-cursor__label': {
          position: 'absolute',
          top: '-18px',
          left: '-1px',
          height: '18px',
          padding: '0 4px',
          fontSize: '12px',
          whiteSpace: 'nowrap',
          color: '#fff',
          borderRadius: '3px 3px 3px 0',
          userSelect: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: 0,
        }}
      >
        {editor && (
          <Box sx={{borderBottom: '1px solid #e6ebf3'}}>
            <EditorToolbar editor={editor} users={[]} />
          </Box>
        )}

        {isLoaded ? <EditorContent editor={editor} /> : <LoadingPlaceholder />}
      </Box>
    </Box>
  );
}
