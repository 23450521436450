import parseISO from 'date-fns/parseISO';

// https://github.com/amannn/next-intl/blob/c7164aee1604ac42879ff5aa66e481f84a68fdc7/packages/use-intl/src/core/createFormatter.tsx
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * (365 / 12); // Approximation
const YEAR = DAY * 365;

const getRelativeTimeFormatConfig = (seconds: number) => {
  const absValue = seconds;
  let unit: Intl.RelativeTimeFormatUnit, value;

  // We have to round the resulting values, as `Intl.RelativeTimeFormat`
  // will include fractions like '2.1 hours ago'.
  if (absValue < MINUTE) {
    unit = 'second';
    value = Math.round(seconds);
  } else if (absValue < HOUR) {
    unit = 'minute';
    value = Math.round(seconds / MINUTE);
  } else if (absValue < DAY) {
    unit = 'hour';
    value = Math.round(seconds / HOUR);
  } else if (absValue < WEEK) {
    unit = 'day';
    value = Math.round(seconds / DAY);
  } else if (absValue < MONTH) {
    unit = 'week';
    value = Math.round(seconds / WEEK);
  } else if (absValue < YEAR) {
    unit = 'month';
    value = Math.round(seconds / MONTH);
  } else {
    unit = 'year';
    value = Math.round(seconds / YEAR);
  }

  return {value, unit};
};

export const formatDate = (dateISO: string, showTime: boolean = false) => {
  let options = {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions;
  if (showTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
  }
  const formater = new Intl.DateTimeFormat('en', options);
  const date = parseISO(dateISO);
  return Number.isNaN(date.getTime()) ? dateISO : formater.format(date);
};

export const formatRelativeTime = (dateISO: string, now: Date): string => {
  if (!dateISO) {
    return 'just now';
  }
  const date = parseISO(dateISO);
  const seconds = Math.abs((date.getTime() - now.getTime()) / 1000);

  if (seconds < MINUTE) {
    return 'just now';
  }

  if (seconds > DAY) {
    return formatDate(dateISO);
  }

  const {unit, value} = getRelativeTimeFormatConfig(seconds);

  const formater = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });

  return formater.format(-value, unit);
};

export const formatTimeZone = (date: Date) => {
  if (date) {
    const timeZone = date?.toString().split('(')[1].split(' ');
    let result = '';
    for (const word of timeZone) {
      result += word[0];
    }
    return result;
  }
};
