'use client';

import {useEffect, useMemo, useRef, useState} from 'react';

import {Box, Button, Divider} from '@mui/material';

import {Layout, Plus} from '@phosphor-icons/react';

import {DashboardCardId} from '@src/components/dashboard/cards/DashboardCardId';
import {Dashboard} from '@src/components/dashboard/Dashboard';
import DashboardAlert from '@src/components/dashboard/DashboardAlert';
import LoadingView from '@src/components/layouts/core/app/LoadingView';
import DataGridZeroState from '@src/components/legacy/Navigator/DataGridZeroState';
import useEntityServiceClient from '@src/hooks/useEntityServiceClient';
import useUser from '@src/hooks/useUser';

const defaultItems: Record<string, DashboardCardId[]> = {
  A: ['pages-viewed'],
  B: ['exported-reels', 'recordings'],
  C: ['personal-notes'],
};

const defaultColumns = ['A', 'B', 'C'];

export default function HomePage() {
  const {user} = useUser();
  const entityServiceClient = useEntityServiceClient();

  const [layoutMode, setLayoutMode] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<Record<string, DashboardCardId[]>>({});
  const [columns, setColumns] = useState([]);
  const [panels, setPanels] = useState<string>();
  const dashboardOwnerId = useRef<string | number>();

  const totalItems = useMemo(() => {
    return Object.values(items).flat().length;
  }, [items]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setIsLoading(true);
      const {data} = await entityServiceClient.findOneByUid(
        'organization-members',
        user.sub,
        {
          fields: ['dashboard'],
        }
      );
      const dashboard = data?.attributes?.dashboard ?? {
        items: defaultItems,
        columns: defaultColumns,
      };

      dashboardOwnerId.current = data.id;
      setItems(dashboard['items']);
      setColumns(dashboard['columns']);
      setPanels(dashboard['panels']);

      setIsLoading(false);
    };
    if (user.sub) {
      fetchDashboard();
    }
  }, [user.sub]);

  const saveDashboard = async () => {
    await entityServiceClient.update(
      'organization-members',
      dashboardOwnerId.current,
      {
        data: {
          dashboard: {
            items,
            columns,
            panels,
          } as any,
        },
      }
    );
  };

  const addFirstColumn = () => {
    setColumns(['A']);
    setItems({['A']: []});
  };

  const getContent = () => {
    if (isLoading) {
      return <LoadingView />;
    }

    if (!layoutMode && totalItems == 0) {
      return (
        <DataGridZeroState
          action={
            <Button
              size="small"
              startIcon={<Layout />}
              variant="contained"
              onClick={() => {
                return setLayoutMode(true);
              }}
            >
              Manage
            </Button>
          }
          icon={<Layout />}
          message="You have no widgets in your Home Dashboard. Click Manage to start customizing your space."
          title="Your Home Dashboard is empty"
        />
      );
    }
    if (layoutMode && columns.length === 0) {
      return (
        <DataGridZeroState
          action={
            <Button
              size="small"
              startIcon={<Plus />}
              variant="contained"
              onClick={addFirstColumn}
            >
              Add Column
            </Button>
          }
          icon={<Layout />}
          message="Customize your dashboard by first adding a column."
          title="Your Home Dashboard is empty"
        />
      );
    }

    return (
      <Dashboard
        columns={columns}
        items={items}
        layoutMode={layoutMode}
        panels={panels}
        setColumns={setColumns}
        setItems={setItems}
        setPanels={setPanels}
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        height: '100%',
      }}
    >
      <DashboardAlert
        enterLayoutMode={() => {
          return setLayoutMode(true);
        }}
        layoutMode={layoutMode}
        lockLayout={async () => {
          await saveDashboard();
          setLayoutMode(false);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <Box sx={{flex: '1', padding: 2}}>{getContent()}</Box>
      </Box>
      <Divider />
    </Box>
  );
}
