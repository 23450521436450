import {List, ListItem, ListItemText} from '@mui/material';

import {ReadCvLogo} from '@phosphor-icons/react';
import useSWR from 'swr';

import Link from '@src/components/core/navigation/Link';

import LoadingView from '@src/components/layouts/core/app/LoadingView';
import DataGridZeroState from '@src/components/legacy/Navigator/DataGridZeroState';
import {useCMSClient} from '@src/hooks/useCMSClient';
import useNow from '@src/hooks/useNow';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';
import {formatRelativeTime} from '@src/library/dates';
import parseRoot from '@src/library/parseUrl';

interface AuditLogPageView {
  id: string;
  distinct_id: string;
  properties: {
    auditLog: string;
    $current_url: string;
  };
  timestamp: string;
}

const PagesViewed = () => {
  const {user} = useUser();
  const subdomain = useSubdomain();
  const CMSClient = useCMSClient();

  const {data, isLoading} = useSWR(['pagesViewed', user.sub], async (key) => {
    const url = new URL('/api/posthog/audit-log', process.env.WEB_BASE_URL);
    url.searchParams.set('limit', '10');
    url.searchParams.set('distinct_id', user.sub);
    url.searchParams.set('eventsFilter', 'Viewed page');
    if (subdomain) {
      url.host = `${subdomain}.${url.host}`;
    }

    const response = await fetch(url);
    const pageViews = (await response.json()) as AuditLogPageView[];

    const parsedPagesViews = pageViews
      ?.map((pageView) => {
        const parsedUrl = new URL(pageView.properties.$current_url);
        const segments = parsedUrl.pathname.split('/').filter((segment) => {
          return segment.length > 0;
        });
        const parsed = parseRoot(segments);

        if (!parsed) {
          return null;
        }

        return {
          ...parsed,
          timestamp: pageView.timestamp,
          url: pageView.properties.$current_url,
        };
      })
      .filter(Boolean);

    const uids = parsedPagesViews
      ?.map((item) => {
        return [item?.entityUid, item?.inProjectUid];
      })
      .flat()
      .filter(Boolean);

    const {assets, insights, projectPlanBlocks, projects, tagsProject} =
      await CMSClient.getPageViewsMetadata({uids});

    for (const parsedPageView of parsedPagesViews) {
      if (parsedPageView.inProjectUid) {
        parsedPageView.inProjectTitle = projects.data.find((item) => {
          return item.attributes.uid === parsedPageView.inProjectUid;
        })?.attributes?.name;
      }

      switch (parsedPageView?.entityType) {
        case 'asset': {
          parsedPageView.title = assets.data.find((item) => {
            return item.attributes.uid;
          })?.attributes?.title;
          break;
        }

        case 'insight': {
          parsedPageView.title = insights.data.find((item) => {
            return item.attributes.uid;
          })?.attributes?.title;
          break;
        }

        case 'tag': {
          parsedPageView.title = tagsProject.data.find((item) => {
            return item.attributes.uid;
          })?.attributes?.content;
          break;
        }

        case 'plan-block': {
          parsedPageView.title = projectPlanBlocks.data.find((item) => {
            return item.attributes.uid;
          })?.attributes?.heading;
          break;
        }
      }
    }

    return parsedPagesViews;
  });

  const now = useNow({updateInterval: 1000 * 30}, []);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data?.length) {
    return (
      <DataGridZeroState
        icon={<ReadCvLogo />}
        message="Here you will see your most recent activity"
        title="Nothing to Show"
      />
    );
  }

  return (
    <List dense disablePadding>
      {data?.map((pageView, index) => {
        const timestamp = formatRelativeTime(pageView.timestamp, now);
        return (
          <ListItem key={index} disablePadding>
            <ListItemText
              primary={
                <>
                  {pageView?.icon}
                  <Link
                    color="unset"
                    href={pageView.url}
                    sx={{wordBreak: 'break-word'}}
                    underline="hover"
                  >
                    {pageView?.inProjectTitle
                      ? `${pageView?.title} in ${pageView?.inProjectTitle}`
                      : pageView?.title}
                  </Link>
                </>
              }
              primaryTypographyProps={{
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                  svg: {
                    flex: 'none',
                  },
                },
              }}
              secondary={timestamp}
              secondaryTypographyProps={{
                sx: {
                  paddingLeft: 3,
                },
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default PagesViewed;
