import {DependencyList, useEffect, useState} from 'react';

interface Options {
  /**
   * Determines how often should the date be updated.
   */
  updateInterval: number;
}

/**
 * React hook that returns a `Date` instance and updates it on a custom interval.
 */
const useNow = ({updateInterval}: Options, dependencies: DependencyList): Date => {
  const [now, setNow] = useState<Date>(() => {
    return new Date();
  });

  useEffect(() => {
    setNow(new Date());

    const timer = setInterval(() => {
      setNow(new Date());
    }, updateInterval);

    return () => {
      clearInterval(timer);
    };
  }, dependencies);

  return now;
};

export default useNow;
