import {forwardRef} from 'react';

import {GridActionsCellItem, GridActionsCellItemProps} from '@mui/x-data-grid-pro';

const Action = forwardRef((props: GridActionsCellItemProps, ref) => {
  return <GridActionsCellItem {...(props as any)} ref={ref} />;
});

Action.displayName = 'Action';

export default Action;
