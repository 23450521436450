import {useMemo} from 'react';

import {EntityServiceClient} from '@src/library/entityServiceClient';

import {useSubdomain} from './useSubdomain';

export default function useEntityServiceClient() {
  const subdomain = useSubdomain();

  return useMemo(() => {
    return new EntityServiceClient(subdomain);
  }, [subdomain]);
}
