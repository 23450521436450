import {Alert, Button} from '@mui/material';
import {Layout, LockSimple} from '@phosphor-icons/react';

import useUser from '@src/hooks/useUser';

interface Props {
  layoutMode: boolean;
  enterLayoutMode: () => void;
  lockLayout: () => void;
}

const DashboardAlert = ({enterLayoutMode, layoutMode, lockLayout}: Props) => {
  const {user} = useUser();

  return (
    <Alert
      action={
        <Button
          size="small"
          startIcon={layoutMode ? <LockSimple /> : <Layout />}
          onClick={() => {
            return layoutMode ? lockLayout() : enterLayoutMode();
          }}
        >
          {layoutMode ? 'Lock' : 'Manage'}
        </Button>
      }
      severity={layoutMode ? 'warning' : 'info'}
      sx={{
        alignItems: 'center',
        whiteSpace: 'break-spaces',
        '.MuiAlert-action': {
          padding: 0,
        },
      }}
    >
      {layoutMode
        ? `You are in layout mode. Here you can customize the dashboard, add new widgets, drag and drop to re-arrange them. You can also add, resize and remove columns. Once your dashboard is complete, press “Lock” to save.`
        : `Hi ${user.name}! Quickly access your projects, interviews and more.`}
    </Alert>
  );
};

export default DashboardAlert;
