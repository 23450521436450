import {FC} from 'react';

import MuiAvatar, {AvatarProps as MuiAvatarProps} from '@mui/material/Avatar';
import Image from 'next/image';

interface AvatarProps extends Omit<MuiAvatarProps, 'src'> {
  src?: string;
  width: number;
  height: number;
}

const Avatar: FC<AvatarProps> = ({alt, height, src, sx, width, ...props}) => {
  if (!src) {
    return <MuiAvatar {...props} sx={{width: width, height: height, ...sx}} />;
  }

  return (
    <MuiAvatar {...props} sx={{width: width, height: height, ...sx}}>
      <Image alt={alt ?? ''} height={height} src={src} width={width} />
    </MuiAvatar>
  );
};

export default Avatar;
