import {ReactNode} from 'react';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {IconButton} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import {SxProps, Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {CaretDown, CaretUp, DotsSixVertical, X} from '@phosphor-icons/react';
import {useLocalStorage} from 'react-use';

import NotificationList from '../notifications/NotificationList';

import {DashboardCardId} from './cards/DashboardCardId';
import ExportedReels from './cards/ExportedReels';
import PagesViewed from './cards/PagesViewed';
import PersonalNotes from './cards/PersonalNotes';
import ProjectsViewed from './cards/ProjectsViewed';
import UpcomingRecordings from './cards/UpcomingRecordings';

interface Props {
  title?: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  reverse?: boolean;
  draggable?: boolean;
  hideable?: boolean;
  cardId?: string;
  onHide?: (cardId: string) => void;
}

const DashboardCard = ({
  cardId,
  children,
  draggable,
  hideable,
  onHide,
  reverse,
  sx,
  title,
}: Props) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: cardId,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const [expanded, setExpanded] = useLocalStorage(
    `dashboard-accordion-${cardId}`,
    true
  );

  return (
    <Accordion
      expanded={expanded}
      sx={{
        margin: '0 !important',
        borderRadius: '8px !important',
        '::before': {
          visibility: 'hidden',
        },
        ...sx,
      }}
      onChange={(_, value) => {
        return setExpanded(value);
      }}
      {...attributes}
      ref={setNodeRef}
      style={style}
    >
      <AccordionSummary
        expandIcon={reverse ? <CaretUp /> : <CaretDown />}
        sx={{
          '.MuiAccordionSummary-content': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Box alignItems="center" display="flex">
          {draggable && (
            <Box
              component={DotsSixVertical}
              fontSize="20px"
              mr={1}
              sx={{
                color: (theme) => {
                  return theme.palette.grays.five;
                },
                cursor: 'move',
              }}
              weight="bold"
              {...listeners}
            />
          )}
          <Typography variant="h4">
            {title ?? DashboardCard.renderTitle(cardId as DashboardCardId)}
          </Typography>
        </Box>

        {hideable && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onHide?.(cardId);
            }}
          >
            <X />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {children ?? DashboardCard.renderContent(cardId as DashboardCardId)}
      </AccordionDetails>
    </Accordion>
  );
};

DashboardCard.renderTitle = (cardId: DashboardCardId) => {
  switch (cardId) {
    case 'exported-reels': {
      return 'Recently Exported Reels';
    }

    case 'mentions': {
      return 'Mentions';
    }

    case 'pages-viewed': {
      return 'Recently Viewed Pages';
    }

    case 'projects-viewed': {
      return 'Recent Projects';
    }

    case 'personal-notes': {
      return 'Personal Notes and To Do';
    }

    case 'recordings': {
      return 'Upcoming Recordings';
    }

    default: {
      return null;
    }
  }
};

DashboardCard.renderContent = (cardId: DashboardCardId) => {
  switch (cardId) {
    case 'exported-reels': {
      return <ExportedReels />;
    }

    case 'mentions': {
      return <NotificationList />;
    }

    case 'pages-viewed': {
      return <PagesViewed />;
    }

    case 'projects-viewed': {
      return <ProjectsViewed />;
    }

    case 'personal-notes': {
      return <PersonalNotes />;
    }

    case 'recordings': {
      return <UpcomingRecordings />;
    }

    default: {
      return null;
    }
  }
};

export default DashboardCard;
