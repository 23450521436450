import ky, {KyInstance, Options} from 'ky';

import qs from 'qs';

import type {
  APINames,
  APIParams,
  APIResponse,
  APIResponseCollection,
  ContentTypesMap,
  CreateAPIParams,
  UpdateAPIParams,
} from '@tetra-next/cms-types';

export class EntityServiceClient {
  private client: KyInstance;

  constructor(subdomain: string | undefined, options: Options = {}) {
    const prefixUrl = new URL('/api', process.env['CMS_BASE_URL']);

    if (subdomain) {
      prefixUrl.host = prefixUrl.host.replace(/^cms\./, `cms.${subdomain}.`);
    }

    this.client = ky.create({
      prefixUrl: prefixUrl.href,
      credentials: 'include',
      timeout: 30_000,
      ...options,
    });
  }

  findMany<API extends APINames, TParams extends APIParams<API>>(
    api: API,
    params?: TParams
  ): Promise<APIResponseCollection<ContentTypesMap[API]> | null> {
    const url = `${api}?${qs.stringify(params)}`;
    return this.client.get(url).json();
  }

  async findOne<API extends APINames, TParams extends APIParams<API>>(
    api: API,
    params?: TParams
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    const {data} = await this.findMany(api, {
      ...params,
      pagination: {
        limit: 1,
      },
    } as APIParams<API>);
    return {
      data: data[0],
    };
  }

  findOneByUid<API extends APINames, TParams extends APIParams<API>>(
    api: API,
    uid: string,
    params?: TParams
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    return this.findOne(api, {
      ...params,
      filters: {
        uid: {
          $eq: uid,
        },
      },
    } as APIParams<API>);
  }

  create<API extends APINames, TParams extends CreateAPIParams<API>>(
    api: API,
    params?: TParams
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    return this.client
      .post(api, {
        json: params,
      })
      .json();
  }

  update<API extends APINames, TParams extends UpdateAPIParams<API>>(
    api: API,
    id: string | number,
    params?: TParams
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    const url = `${api}/${id}`;

    return this.client
      .put(url, {
        json: params,
      })
      .json();
  }

  softDelete<API extends APINames>(
    api: API,
    id: string | number
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    return this.update(api, id, {
      data: {
        publishedAt: null,
      } as any,
    });
  }

  delete<API extends APINames>(
    api: API,
    id: string | number
  ): Promise<APIResponse<ContentTypesMap[API]> | null> {
    const url = `${api}/${id}`;
    return this.client.delete(url).json();
  }
}
