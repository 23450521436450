// Source: https://stackoverflow.com/a/67469832/651929

import {Ref, forwardRef} from 'react';

import MuiLink from '@mui/material/Link';
import NextLink from 'next/link';

import {LinkProps} from './Link.types';

const Link = (
  {children, href, ...others}: LinkProps,
  ref: Ref<HTMLAnchorElement>
) => {
  if (!href) {
    return <>{children}</>;
  }
  return (
    <MuiLink ref={ref} component={NextLink} href={href} {...others}>
      {children}
    </MuiLink>
  );
};

export default forwardRef<HTMLAnchorElement, LinkProps>(Link);
