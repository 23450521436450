import {useEffect} from 'react';

import {Room} from '@liveblocks/client';
import {LiveblocksYjsProvider} from '@liveblocks/yjs';
import {useActor} from '@xstate/react';
import {ActorRefFrom, assign, createMachine, interpret} from 'xstate';
import {Doc} from 'yjs';

type RoomMachineContext = {
  doc: Doc | null;
  provider: LiveblocksYjsProvider<any, any, any, any> | null;
  references: number;
};

type EventObject = ReferenceEventObject | UnreferenceEventObject;

type ReferenceEventObject = {
  type: 'REFERENCE';
  data: {
    room: Room<any, any, any, any>;
  };
};

type UnreferenceEventObject = {
  type: 'UNREFERENCE';
};

const roomMachine = createMachine<RoomMachineContext, EventObject>(
  {
    predictableActionArguments: true,
    schema: {
      context: {} as RoomMachineContext,
    },
    context: {
      doc: null,
      provider: null,
      references: 0,
    },
    initial: 'unused',
    states: {
      unused: {
        on: {
          REFERENCE: {
            target: 'used',
            actions: 'incrementReferences',
          },
        },
      },
      used: {
        entry: ['createDoc', 'createProvider'],
        exit: ['destroyDoc', 'destroyProvider'],
        on: {
          REFERENCE: {
            actions: 'incrementReferences',
          },
          UNREFERENCE: [
            {
              cond: 'hasOneReference',
              target: 'unused',
              actions: 'decrementReferences',
            },
            {
              actions: 'decrementReferences',
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      createDoc: assign({
        doc: () => {
          return new Doc();
        },
      }),
      createProvider: assign({
        provider: (context, event) => {
          if (event.type === 'REFERENCE') {
            return new LiveblocksYjsProvider(event.data.room, context.doc);
          }

          return context.provider;
        },
      }),
      destroyDoc: assign({
        doc: (context) => {
          context.doc.destroy();
          return null;
        },
      }),
      destroyProvider: assign({
        provider: (context) => {
          context.provider.destroy();
          return null;
        },
      }),
      incrementReferences: assign({
        references: (context) => {
          return context.references + 1;
        },
      }),
      decrementReferences: assign({
        references: (context) => {
          return context.references - 1;
        },
      }),
    },
    guards: {
      hasOneReference: (context) => {
        return context.references === 1;
      },
    },
  }
);

const roomActors = new Map<string, ActorRefFrom<typeof roomMachine>>();

function getRoomActor(roomId: string): ActorRefFrom<typeof roomMachine> {
  let actor = roomActors.get(roomId);

  if (!actor) {
    actor = interpret(roomMachine).start();
    roomActors.set(roomId, actor);
  }

  return actor;
}

export default function useYjsRoom(
  room: Room<any, any, any, any>
): Pick<RoomMachineContext, 'doc' | 'provider'> {
  const [state, send] = useActor(getRoomActor(room.id));

  useEffect(() => {
    if (!room) {
      return;
    }

    send({
      type: 'REFERENCE',
      data: {room},
    });

    return () => {
      send('UNREFERENCE');
    };
  }, [room]);

  return {
    doc: state.context.doc,
    provider: state.context.provider,
  };
}
