import {useMemo, useRef, useState} from 'react';

import {UniqueIdentifier} from '@dnd-kit/core';
import {
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@mui/material';
import {Plus, PuzzlePiece} from '@phosphor-icons/react';

import {difference} from 'lodash';

import {DashboardCardId} from './cards/DashboardCardId';
import DashboardCard from './DashboardCard';

interface Props {
  items: Record<UniqueIdentifier, DashboardCardId[]>;
  onSelect: (item: DashboardCardId) => void;
}

const allItems: DashboardCardId[] = [
  'pages-viewed',
  'projects-viewed',
  'personal-notes',
  'exported-reels',
  'mentions',
  'recordings',
];

const NewCardButton = ({items, onSelect}: Props) => {
  const anchorElement = useRef<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(false);

  const missingItems = useMemo(() => {
    return difference(allItems, Object.values(items).flat());
  }, [items]);

  const selectItem = (item: DashboardCardId) => {
    onSelect(item);
    setIsOpen(false);
  };

  if (missingItems.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        ref={anchorElement}
        size="small"
        startIcon={<Plus />}
        variant="contained"
        onClick={() => {
          return setIsOpen(true);
        }}
      >
        Add Widget
      </Button>
      <Popper
        anchorEl={anchorElement.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 4],
            },
          },
        ]}
        open={isOpen}
        sx={{zIndex: 1}}
      >
        <ClickAwayListener
          onClickAway={() => {
            return setIsOpen(false);
          }}
        >
          <Box
            sx={{
              minWidth: '300px',
              maxWidth: '500px',
              padding: '8px',
              background: 'white',
              borderRadius: '8px',
              boxShadow:
                '0 16px 32px 0 #00000019, 0 1px 4px 0 #00000026, 0 0 1px 0 #00000026',
            }}
          >
            <List dense disablePadding>
              {missingItems.map((cardId) => {
                return (
                  <ListItem key={cardId} disableGutters>
                    <ListItemButton
                      onClick={() => {
                        return selectItem(cardId);
                      }}
                    >
                      <ListItemIcon sx={{justifyContent: 'center'}}>
                        <PuzzlePiece />
                      </ListItemIcon>
                      <ListItemText secondary={DashboardCard.renderTitle(cardId)} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default NewCardButton;
