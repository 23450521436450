export type EntityType =
  | 'audienceProfile'
  | 'projectPlanBlock'
  | 'assetNotes'
  | 'insightNotes'
  | 'personalNotes'
  | 'transcript';

export type EntityRoom = {
  subdomain: string;
  organizationId: string;
  entityType: EntityType;
  entityUid: string;
};

export function generateEntityRoomId({
  subdomain,
  organizationId,
  entityType,
  entityUid,
}: EntityRoom): string {
  return [subdomain, organizationId, entityType, entityUid].join(':');
}

export function parseEntityRoomId(roomId: string): EntityRoom {
  const parts = roomId.split(':');
  const includesProjectUid = parts.length === 5;

  if (includesProjectUid) {
    throw new Error(`Invalid entity room ID "${roomId}"`);
  }

  const subdomain = parts[0];
  const organizationId = parts[1];
  const entityType = includesProjectUid ? parts[3] : parts[2];
  const entityUid = includesProjectUid ? parts[4] : parts[3];

  return {
    subdomain,
    organizationId,
    entityType: entityType as EntityType,
    entityUid,
  };
}
