import {Chip, IconButton, List, ListItem, ListItemText} from '@mui/material';
import {ArrowSquareOut, FilmStrip} from '@phosphor-icons/react';

import useSWR from 'swr';

import Link from '@src/components/core/navigation/Link';
import LoadingView from '@src/components/layouts/core/app/LoadingView';
import DataGridZeroState from '@src/components/legacy/Navigator/DataGridZeroState';
import {useCMSClient} from '@src/hooks/useCMSClient';
import useNow from '@src/hooks/useNow';
import useUser from '@src/hooks/useUser';
import {formatRelativeTime} from '@src/library/dates';
import parseRoot from '@src/library/parseUrl';

const getStateColor = (state) => {
  switch (state) {
    case 'FAILED': {
      return 'error';
    }
    case 'SUCCEEDED': {
      return 'success';
    }
    default: {
      return 'info';
    }
  }
};

const ExportedReels = () => {
  const CMSClient = useCMSClient();
  const {user} = useUser();

  const {data: highlightReelsData, isLoading} = useSWR(
    {
      filters: {
        userEmail: {
          eq: user.email,
        },
      },
      query: 'getHighlighReels',
    },
    async (key) => {
      const result = await CMSClient.getHighlightReels(key);
      const highlightReels = result?.highlightReels?.data ?? [];
      const parsedHighlightReels = highlightReels?.map((highlightReel) => {
        const url = new URL(highlightReel.attributes.originUrl);
        const segments = url.pathname.split('/').filter((segment) => {
          return segment.length > 0;
        });
        const parsed = parseRoot(segments);

        return {
          ...highlightReel,
          parsedOriginUrl: parsed ?? {},
        };
      });

      const uids = parsedHighlightReels
        ?.map((item) => {
          return [
            item?.parsedOriginUrl?.entityUid,
            item?.parsedOriginUrl?.inProjectUid,
          ];
        })
        .flat()
        .filter(Boolean);

      const {assets, insights, projects, tagsProject} =
        await CMSClient.getPageViewsMetadata({uids});

      for (const parsedHighlightReel of parsedHighlightReels) {
        if (parsedHighlightReel.parsedOriginUrl.inProjectUid) {
          parsedHighlightReel.parsedOriginUrl.inProjectTitle = projects.data.find(
            (item) => {
              return (
                item.attributes.uid ===
                parsedHighlightReel.parsedOriginUrl.inProjectUid
              );
            }
          )?.attributes?.name;
        }

        switch (parsedHighlightReel.parsedOriginUrl.entityType) {
          case 'asset': {
            parsedHighlightReel.parsedOriginUrl.title = assets.data.find((item) => {
              return item.attributes.uid;
            })?.attributes?.title;
            break;
          }

          case 'insight': {
            parsedHighlightReel.parsedOriginUrl.title = insights.data.find(
              (item) => {
                return item.attributes.uid;
              }
            )?.attributes?.title;
            break;
          }

          case 'tag': {
            parsedHighlightReel.parsedOriginUrl.title = tagsProject.data.find(
              (item) => {
                return item.attributes.uid;
              }
            )?.attributes?.content;
            break;
          }
        }
      }

      return parsedHighlightReels;
    }
  );

  const now = useNow({updateInterval: 1000 * 30}, []);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!highlightReelsData?.length) {
    return (
      <DataGridZeroState
        icon={<FilmStrip />}
        message="Export some Highlight Reels"
        title="Nothing to Show"
      />
    );
  }

  return (
    <List dense disablePadding>
      {highlightReelsData?.map((highlightReel) => {
        const timestamp = formatRelativeTime(
          highlightReel.attributes.createdAt,
          now
        );

        return (
          <ListItem
            key={highlightReel.id}
            disablePadding
            secondaryAction={
              highlightReel?.attributes?.signedUrl && (
                <IconButton size="small">
                  <a
                    href={highlightReel?.attributes?.signedUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <ArrowSquareOut color="#3E5FE4" />
                  </a>
                </IconButton>
              )
            }
            sx={{gap: 1}}
          >
            <ListItemText
              primary={
                <>
                  {highlightReel.parsedOriginUrl.icon}
                  <Link
                    color="unset"
                    href={highlightReel.attributes.originUrl}
                    sx={{wordBreak: 'break-word'}}
                    underline="hover"
                  >
                    {highlightReel.parsedOriginUrl.inProjectTitle
                      ? `${highlightReel.parsedOriginUrl.title} in ${highlightReel.parsedOriginUrl.inProjectTitle}`
                      : highlightReel.parsedOriginUrl.inProjectTitle}
                  </Link>
                </>
              }
              primaryTypographyProps={{
                sx: {
                  display: 'inline-flex',
                  alignItems: 'baseline',
                  gap: 1,
                  svg: {
                    flex: 'none',
                  },
                },
              }}
              secondary={
                <>
                  <Chip
                    color={getStateColor(highlightReel.attributes.state)}
                    component="span"
                    label={highlightReel.attributes.state}
                    size="small"
                  />
                  {timestamp}
                </>
              }
              secondaryTypographyProps={{
                sx: {
                  display: 'flex',
                  alignItems: 'baseline',
                  gap: 1,
                  paddingTop: 0.5,
                  paddingLeft: 3,
                },
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ExportedReels;
