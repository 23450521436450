import {
  CheckSquare,
  Link,
  ListBullets,
  ListNumbers,
  Minus,
  Quotes,
  TextBolder,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextItalic,
  TextStrikethrough,
} from '@phosphor-icons/react';
import {Editor} from '@tiptap/react';
import {find, test} from 'linkifyjs';

import ToolbarButton from './ToolbarButton';

type Props = {
  editor: Editor;
  isDisabled: boolean;
};

export default function FormattingToolbarButtons({editor, isDisabled}: Props) {
  return (
    <>
      <ToolbarButton
        isActive={editor.isActive('heading', {level: 1})}
        isDisabled={isDisabled}
        label="First-level heading"
        onClick={() => {
          editor.chain().focus().toggleHeading({level: 1}).run();
        }}
      >
        <TextHOne />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('heading', {level: 2})}
        isDisabled={isDisabled}
        label="Second-level heading"
        onClick={() => {
          editor.chain().focus().toggleHeading({level: 2}).run();
        }}
      >
        <TextHTwo />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('heading', {level: 3})}
        isDisabled={isDisabled}
        label="Third-level heading"
        onClick={() => {
          editor.chain().focus().toggleHeading({level: 3}).run();
        }}
      >
        <TextHThree />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('bold')}
        isDisabled={isDisabled}
        label="Bold"
        onClick={() => {
          editor.chain().focus().toggleBold().run();
        }}
      >
        <TextBolder />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('italic')}
        isDisabled={isDisabled}
        label="Italic"
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
        }}
      >
        <TextItalic />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('strike')}
        isDisabled={isDisabled}
        label="Strike"
        onClick={() => {
          editor.chain().focus().toggleStrike().run();
        }}
      >
        <TextStrikethrough />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('bulletList')}
        isDisabled={isDisabled}
        label="Bullet list"
        onClick={() => {
          editor.chain().focus().toggleBulletList().run();
        }}
      >
        <ListBullets />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('orderedList')}
        isDisabled={isDisabled}
        label="Ordered list"
        onClick={() => {
          editor.chain().focus().toggleOrderedList().run();
        }}
      >
        <ListNumbers />
      </ToolbarButton>

      <ToolbarButton
        key="checkbox"
        isActive={editor.isActive('taskList')}
        isDisabled={isDisabled}
        label="Task list"
        onClick={() => {
          editor.chain().focus().toggleTaskList().run();
        }}
      >
        <CheckSquare />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('blockquote')}
        isDisabled={isDisabled}
        label="Blockquote"
        onClick={() => {
          editor.chain().focus().toggleBlockquote().run();
        }}
      >
        <Quotes />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('horizontalRule')}
        isDisabled={isDisabled}
        label="Horizontal rule"
        onClick={() => {
          editor.chain().focus().setHorizontalRule().run();
        }}
      >
        <Minus />
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isActive('link')}
        isDisabled={isDisabled}
        label="Link"
        onClick={() => {
          const previousUrl = editor.getAttributes('link').href;
          const url = window.prompt('URL', previousUrl);

          // cancelled
          if (url === null) {
            return;
          }

          // empty
          if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();

            return;
          }

          // update link
          if (test(url)) {
            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({href: find(url)[0].href})
              .run();
          }
        }}
      >
        <Link />
      </ToolbarButton>
    </>
  );
}
